@import "~@/erp/styles/variables/variables.scss";

































































































































































































































































































































































































.table-fixed {
  height: 400px !important;
}
::v-deep {
  // .el-table__body-wrapper {
  //   overflow-x: overlay !important;
  //   width: 100% !important;
  // }
  .el-table--border th.gutter:last-of-type {
    display: block !important;
    width: 17px !important;
  }
}
